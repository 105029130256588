var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "", disabled: _vm.isSpaceResting } },
    [
      _c("v-card-title", [
        _c("div", { staticClass: "mb-3 d-flex flex-column" }, [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("span", { staticClass: "secondary--text font-weight-bold" }, [
                _vm._v("Snapshots"),
              ]),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  icon: "",
                                  loading: _vm.fetchingSnapshots,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$store.dispatch(
                                      "instanceStore/fetchInstanceSnapshots",
                                      _vm.$route.params.iid
                                    )
                                  },
                                },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("refresh")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Refresh snapshots")])]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _vm.nonDevelopmentSnapshots.length && !_vm.fetchingSnapshots
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex justify-start flex-wrap" },
                        _vm._l(
                          Object.values(_vm.snapshotCheckboxBinders),
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "mr-5" },
                              [
                                _c("v-checkbox", {
                                  staticClass: "ma-0 pa-0",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "d-flex align-center",
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      item.color,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fiber_manual_record"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "secondary--text caption text-uppercase",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.text
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(item.description)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: item.status,
                                    callback: function ($$v) {
                                      _vm.$set(item, "status", $$v)
                                    },
                                    expression: "item.status",
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mb-5" }),
                  _vm.nonDevelopmentSnapshots.length
                    ? _c(
                        "v-timeline",
                        {
                          staticClass: "shepherd-snapshot-restore-1",
                          attrs: { dense: "" },
                        },
                        _vm._l(_vm.filteredSnapshots, function (snapshot) {
                          return _c(
                            "v-timeline-item",
                            {
                              key: snapshot.snid,
                              staticClass: "pb-0",
                              attrs: {
                                "fill-dot": "",
                                right: "",
                                small: "",
                                color: _vm.dotColor(snapshot),
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "align-center my-1" },
                                [
                                  _c("v-col", { attrs: { cols: "auto" } }, [
                                    _c("div", { staticClass: "mr-4" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateTimeToHuman")(
                                              snapshot.snapshot_timestamp
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "px-5",
                                          attrs: { elevation: "3" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { align: "center" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "text-decoration-none secondary--text subtitle-1",
                                                      attrs: {
                                                        to: {
                                                          name: "snapshot-overview",
                                                          params: {
                                                            oid: _vm.$route
                                                              .params.oid,
                                                            sid: _vm.$route
                                                              .params.sid,
                                                            iid: _vm.$route
                                                              .params.iid,
                                                            snid: snapshot.snid,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            snapshot.long_id
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  snapshot.description
                                                    ? _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              snapshot.description
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "1" } },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        icon: "",
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            snapshot.filesystem_prefix
                                                                              ? "success"
                                                                              : "info",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.getSnapshotStatusIcon(
                                                                                snapshot
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getSnapshotStatusDescription(
                                                                snapshot
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [
                                                  snapshot.fhl_total_bytes
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-chip",
                                                                      _vm._g(
                                                                        {},
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.humanFileSize(
                                                                              snapshot.fhl_total_bytes
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "Backup size"
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _vm.isInstanceEditor
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex align-center justify-end",
                                                        },
                                                        [
                                                          !_vm.isDistributedInstance &&
                                                          !_vm.isSpaceInAnyRestStateExceptPreResting
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "shepherd-snapshot-restore-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "snapshot-restore-dialog",
                                                                    {
                                                                      attrs: {
                                                                        snapshotData:
                                                                          snapshot,
                                                                        isRestoreToCurrentInstance: true,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          !_vm.isDistributedInstance
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "px-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "snapshot-restore-dialog",
                                                                    {
                                                                      attrs: {
                                                                        snapshotData:
                                                                          snapshot,
                                                                        isRestoreToCurrentInstance: false,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "shepherd-snapshot-restore-3",
                                                            },
                                                            [
                                                              _c(
                                                                "snapshot-delete-dialog",
                                                                {
                                                                  attrs: {
                                                                    snapshotData:
                                                                      snapshot,
                                                                    disabled:
                                                                      _vm.isInstanceArchived &&
                                                                      !_vm.isSpaceAdmin,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm.fetchingSnapshots
            ? _c(
                "div",
                _vm._l(
                  [
                    { id: "c1", opacity: 1 },
                    { id: "c2", opacity: 0.75 },
                    { id: "c3", opacity: 0.5 },
                  ],
                  function (item) {
                    return _c("div", { key: item.id }, [
                      _c(
                        "div",
                        { style: { opacity: item.opacity } },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: { type: "list-item-three-line" },
                          }),
                        ],
                        1
                      ),
                    ])
                  }
                ),
                0
              )
            : !_vm.fetchingSnapshots && !_vm.nonDevelopmentSnapshots.length
            ? _c(
                "v-banner",
                { attrs: { "two-line": "" } },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: { slot: "icon", color: "white", size: "60" },
                      slot: "icon",
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { "x-large": "", icon: "info", color: "info" },
                        },
                        [_vm._v("info")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: { "max-width": "900px" },
                    },
                    [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("No snapshots found"),
                      ]),
                      _c("span", { staticClass: "mt-1" }, [
                        _vm._v(
                          " Snapshots are immutables states of previous work that allow you to reproduce results and restore files, applications, and data. Check the "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://docs.nuvolos.cloud/features/snapshots/create-a-snapshot",
                              target: "_blank",
                            },
                          },
                          [_vm._v("documentation here")]
                        ),
                        _vm._v(
                          " for information on how to create a snapshot. "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }